import React from 'react';
import {useNavigate } from "react-router-dom";
import { BackIconModal } from './Icons';

const BackBotton = () => {
  const navigate = useNavigate ();
  
  const click = () =>{
      navigate(-1);
  }

  return (
    <button className="back_btn" onClick={() => click()}>
        <BackIconModal/>
      </button>
  );
};

export default BackBotton;