import React,  {useEffect, forwardRef, useImperativeHandle, useState} from 'react';

import {like, sendComment, unlike} from "../../livevoyage/api_client";
import ic_avatar from "../../assets/avatar.png";

import TextStreaming from "./TextStreaming";
import {SendIcon, ShareIcon, ViewIcon, LikeIcon, 
    LikedIcon, CommentIcon, CommentFilledIcon, 
    DotsIcon} 
    from "../icons/Icons";
import config from '../../config/config';
import StreamStates from '../../utils/streamStates';

const StreamingView = forwardRef (( props, ref) => {
    
    const [comments, setComments] = useState([]);
    const [additions, setAdditions] = useState([]);
    const [currentUrl, setCurrentUrl] = useState(-1);
    const [views, setViews] = useState(0);
    const [likes, setLikes] = useState(0);
    const [liked, setLiked] = useState(props.stream.liked);
    const [state, setState] = useState(props.stream.state);
    const [newComment, setNewComment] = useState("");
    const [running, setRunning] = useState(true);
    const [commentOpen, setCommentOpen] = useState(false);

    const [textStreaming, setTextstreaming] = useState(new TextStreaming(props.stream, null));

    let tempcommwnts = [];
    //let textStreaming = new TextStreaming(stream, null);

    const onNewComment = (newComments)=>{
        tempcommwnts = tempcommwnts.concat(newComments);
        setComments(tempcommwnts);
    }

    useImperativeHandle(ref, () => ({
        closeStream() {
            setRunning(false);
            textStreaming.stop();
        }
    }));

    const onNewLikes = (count)=>{
        //props.stream.like = count;
        setLikes(count);
    }

    const onNewView = (count)=>{
        setViews(count);
    }
    const getNextUrl=()=>{
        if(additions.length == 0) return currentUrl;
        if(currentUrl == -1) return 0;
        if(currentUrl + 1 == additions.length) return -1;
        return currentUrl + 1;
    }
    const onChange = ()=>{
        let curr = getNextUrl();
        if(curr != currentUrl){
            setCurrentUrl(curr);
            props.onChange(curr == -1 ? props.stream.url:additions[curr].url);
        }
    }

    const onEnd = ()=>{
        props.onClose();
    }
    const send_comment = async (e) => {
        e.preventDefault();
        let body = {comment: newComment}
        let data = await sendComment(props.stream.id, body);
        setNewComment("");
    }
    const like_listener = async () => {
        if(props.stream.liked){
            let res = await unlike(props.stream.id);
            if(res) {
                props.stream.liked = 0;
                setLiked(0);
            }
        }
        else {
            let res = await like(props.stream.id);
            if(res){
                props.stream.liked = 1;
                setLiked(1);
            }
        }
    }

    const openComment =()=>{
        setCommentOpen(!commentOpen);
    } 

    const onNewAdditions = (newAdditions) => {
        setAdditions(newAdditions);
        props.onNewAdditions(newAdditions);
    }
    useEffect(() => {
        if (props.opened) textStreaming.run(onNewComment, onNewLikes, onNewView, onNewAdditions, onEnd);
    },[textStreaming]);

    if(props.stream && (state == StreamStates.Live || state == StreamStates.Saved))
    return (
        <div className='streaming-view'>
            <div className='streaming-controls'>
                <div className='control-items'>
                    <ViewIcon />
                    <p className='icon-text'>{views}</p>
                </div>
                <div className='control-items' onClick={like_listener}>
                     {liked == 1 ? <LikedIcon/>:<LikeIcon />}
                    <p className='icon-text'>{likes}</p>
                </div>
                
                {/*props.stream.is_multy && <div className='control-items' onClick={onChange}>
                    <LikedIcon/>
    </div>*/}
                <div className='control-items'>
                    <ShareIcon />
                </div>
                <div className='control-items' onClick={openComment}>
                    {commentOpen?<CommentFilledIcon />:<CommentIcon />}
                </div>
            </div>
            {commentOpen && <div className='streaming-comment-block'><form action="" onSubmit={send_comment}>
                <div className='streaming-comment-send'>
                    <input className='comment-input comment-text'
                        placeholder="Comment" type="text" name="comment"
                        value={newComment} onChange={(e)=>{setNewComment(e.target.value)}}/>
                    <div className='comment-button' onClick={send_comment}><SendIcon /></div>
                </div></form>
                <div className='streaming-comments'>
                    {
                        comments.map(x => (
                            <div className='streaming-comment'>
                                {x.User && <img className='comment-image' 
                                    src={(x.User.image != "")?config.http.host+x.User.image: ic_avatar}/>}
                                <div className='comment-detail'>
                                    {x.User &&<p className='comment-user'>{x.User.username}</p>}
                                    <p className='comment-text'>{x.comment}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>}
        </div>
    );
    return null;
});

export default StreamingView;
