import React from 'react';
import ic_avatar from "../../assets/avatar.png"
import { useNavigate } from 'react-router-dom';


import config from '../../config/config';

const UserCard = ({user }) => {
    const navigate = useNavigate();
    
    const onClickUser = ()=>{
        navigate('/profile/'+user.id);
    }
    if(user)
    return (
        <div className="user-card" onClick={onClickUser}>
            <div className="user-card-header items-center">
                <img className='user-card-image' src={(user.image != "")
                        ?config.http.host+user.image: ic_avatar}/>
                        
                <div className='user-card-block'>
                    <p className="user-card-text-bold text-center">{user.username}</p>
                    <p className="user-card-text text-center">{user.fullName}</p>
                </div>
            </div>
            <div className='user-card-block'>
                <div className='d_row'>
                    <div className='d_column items-center'>
                        <p className="user-card-text text-center">
                            Followers
                        </p>
                        <p className="user-card-text-bold text-center">
                            {user.followers}
                        </p>
                    </div>
                    <div className='d_column items-center'>
                        <p className="user-card-text text-center">
                            Followings
                        </p>
                        <p className="user-card-text-bold text-center">
                            {user.followings}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserCard;
