import React,  {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {login, getMe, checkLoggenIn, resetPassword} from '../../livevoyage/api_client';

const ResetPassword = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [resultMess, setResultMess] = useState("");

    const navigate = useNavigate ();

    const handleEmail =(event) => {
        setEmail(event.target.value);
    }

    const sendData = async() =>{        
        if(email == ""){
            setError("Email is required!");
            return;
        }
        setError("");
        let res = await resetPassword(email);
        console.log(res);
        if(res){
            setResultMess(res.message);
        }else {
            setError("User not found!");
        }
    }

    useEffect(() => {
        let temp =  checkLoggenIn();
        if(temp){
            navigate('/');
        }
    })
    return (
        <div className='content-box'>
            <div className='centered'>
                {resultMess == "" ? 
                <div className='custom-form'> 
                    <div className='form-title'>
                        Reset password
                    </div>
                    <input className='form-input form-text' 
                        placeholder="Email" type="email" name="email"
                        value={email.value} onChange={handleEmail}/>
                    <div className='form-footer'>
                        {error!= "" &&<p className='error-message'>{error}</p>}
                        <button className='botton2 header-item header-text' onClick={()=>{sendData()}}>
                            Reset
                        </button>
                    
                        <div className='header-item header-text cust-but' onClick={()=>{navigate('/sign-up')}}>
                            Register
                        </div>
                        <div className='header-item header-text cust-but' onClick={()=>{navigate('/login')}}>
                            Login
                        </div>
                    </div>
                </div>
                :
                <div className='custom-form'>
                    <div className='form-title'>
                        {resultMess}
                    </div>
                    <button className='botton2 header-item header-text' onClick={()=>{navigate('/')}}>
                        Home
                    </button>
                </div>
                }
           </div>
        </div>
    );
};

export default ResetPassword;
