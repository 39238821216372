import React from 'react';
import { DropDownIcon, ArrowIcon } from '../components-v2/icons/Icons';

const LButton = (props) => {
    return ( 
        <>
            {props.type  == 1 &&
                <div className='l_button l_button_type1 l_button_text'>
                    {props.children}
                </div>
            }
            {props.type  == 2 &&
                <div className='l_button l_button_text'>
                    {props.children}
                </div>
            }
            {props.type  == 3 &&
                <div className='l_button l_button_text'>
                    {props.children} <DropDownIcon/>
                </div>
            }
            {props.type  == 4 &&
                <div className='l_button l_button_text'>
                    {props.children} <ArrowIcon/>
                </div>
            }
        </>
    );
};

export default LButton;
