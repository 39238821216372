import React from 'react';
import logo from '../logo.svg';
import google from '../assets/googleplay.svg';
import apple from '../assets/apple.svg';

import { FacebookIcon, TwitterIcon, InstagramIcon, LinkedinIcon, TikTokIcon } from './icons/Icons';

import { checkLoggenIn, logout } from '../livevoyage/api_client';
import {useNavigate } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';

const Footer = () => {
  const navigate = useNavigate ();
  
  const aboutClick = () =>{
      navigate('/about');
  }

  const termsClick = () =>{
      navigate('/terms');
  }
  const dataClick = () =>{
      navigate('/terms-conf');
  }
  return (
    <div className='l_footer'>
      <div className='row'> 
        <div className='col2 l_footer_text'>
          <div className='row '>
            “ESMUS” Sp.z.o.o.<br/>
            Poland,  street Sranislawa Leszczynskiego 4/29<br/>
            Wroclaw, 50-078 
          </div>
          <div className='row '>
            NIP: 8971911754<br/>
            Tel: + 48 503 45 33 45<br/>
            E-mail: info@esmus.pro<br/>
          </div>
        </div>
        <div className='col2'>
          <div className='row12'>
            <div className='l_footer_menu'>
              <div className='l_footer_menu_item' onClick={aboutClick}>
                About
              </div>
              <div className='l_footer_menu_item' onClick={aboutClick}>
                Mission
              </div>
              <div className='l_footer_menu_item' onClick={aboutClick}>
                Values
              </div>
              <div className='l_footer_menu_item' onClick={termsClick}>
                Terms of use
              </div>
              <div className='l_footer_menu_item' onClick={dataClick}>
                Data Policy
              </div>
              <div className='l_footer_menu_item' onClick={termsClick}>
                Contact
              </div>
            </div>
          </div>
          <div className='row12'>
            <div className='l_footer_icon_menu'>
              <div className='l_footer_inner_icon_menu'>
                <div className='cust-but'>
                  <FacebookIcon/>
                </div>
                <div className='cust-but'>
                  <TwitterIcon/>
                </div>
                <div className='cust-but'>
                  <InstagramIcon/>
                </div>
                <div className='cust-but'>
                  <LinkedinIcon/>
                </div>
                <div className='cust-but'>
                  <TikTokIcon/>
                </div>

              </div>
              <div className='cust-but'>
                <a target="_blank" href='https://play.google.com/store/apps/details?id=com.livevoyage.livevoyager'>
                  <img src={google} height='55'/>
                </a>
              </div>
              <div className='cust-but'>
                <a target="_blank" href='https://play.google.com/store/apps/details?id=com.livevoyage.livevoyager'>
                  <img src={apple} height='55'/>
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='l_footer_text'>
        All rights reserved © 2024. Livevoyage.club is registered trade mark, owned by Esmus Sp.z.o.o.
      </div>
    </div>
  );
};

export default Footer;
