const wifi1 = "192.168.43.186";
const office = "192.168.0.105";
const wifi2 = "192.168.0.101";

const ip = office;

const confs = {
    local:{
        host: "http://"+ip+":8899",
        ip: ip,
        port: 8899,
    },
    prod:{
        host: "https://livevoyage.club",
        ip: "livevoyage.club",
        port: 8890,
    },
}

const config = {
    rtmp:{
        hostname: ip,
        port: 8888,
    },
    
    http: confs.prod,
    front: {
        ip: 'localhost',
        port: 3000,
        hostname: 'localhost:3000'
    }
};

module.exports = config;
