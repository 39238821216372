import React,  {useEffect, useState} from 'react';

import ContentWrapper from '../../components-v2/contentWrapper';


const About = ( ) => {
    return (
        
    <ContentWrapper>

    <div className='page-content'>
                <div className='section top-100'>
                    <div className='row'>
                        <div className='title_1'>
                        Platform’s description and main recommendations for streamers
                        </div>
                    </div>
                    <div className='row'>
                        <div className='secondary-title left-text'>
                        Platform’s description
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'> 
                            <ol><li>Registration is obligated for making streams. Without registration 
                                it’s impossible to start streaming. Please indicate valid information 
                                as it will be used later, it’s always nice for viewers to know  the actual 
                                name of the person who shows them such interesting places. 
                            </li><li>Before to start streaming. When you are planning to make a live 
                                stream it is useful for your viewers to get basic information about 
                                your plans, about the location which you are going to show.  
                            </li><li>You have the following options: 
                                <ul><li>“Start” means that you start a stream right now.
                                </li><li>“Plan” means that you are planning your streams.
                                </li><li>“Description” – information about location you are going to show by live video streams.
                                </li><li>“Location” – it’s chosen automatically by your actual location or you can choose by yourself (for example: Asia, Indonesia, Jakarta).
                                </li><li>“Date” – actual date for live video stream and the date can be chosen for planned live video stream.
                                </li><li>“Express” – If you activate the “Express” button, 
                                    you can choose the duration of the live video stream from 
                                    1 minute (for making short presentations) to 60 minutes. 
                                    If the button isn’t activated, the live video stream duration 
                                    is unlimited.
                                </li></ul>
                            </li><li>After that you push the “Start” button, for the live video 
                                stream will start immediately, for the planned stream the live video 
                                stream will start at the planned time, you’ll get 10 minutes 
                                notification before the live video stream starts. 
                            </li><li>You can share your live video stream with your friends.
                            </li></ol>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='secondary-title left-text'>
                        Recommendations
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'> 
                            <ol><li>All need for live video streaming – good location which 
                                will be interesting for viewers, your smartphone and good mobile internet connection.
                            </li><li>Please be polite, don’t show any shock or sexual content, any prohibited material during live video stream, also follow the platform’s commitments: 
                                <ul><li>NO harmful content
                                </li><li>NO hate
                                </li><li>NO misinformation
                                </li><li>NO extremist content
                                </li><li>NO bias
                                </li><li>NO politics
                                </li></ul>
                            </li><li>When you make the live video streams, it’s better to make horizontal videos, instead of vertical.
                            </li></ol>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-note left-text'>
                            All together we will make the best live video stream platform for travelling – Livevoyage.club
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='title_1'>
                            Monetization
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'>
                        We highly appreciate your efforts, your time you spend making live
                        streams and we are ready to share with you the platform’s generated 
                        revenue. After starting the monetization program we will inform each 
                        streamer about terms and conditions of monetization. 
                        For the initial stage of the platform's development the monetization is not available.
                        </div>
                        <br/><br/><br/><br/>
                        <div className='page-p left-text'>
                        We suppose that the platform will give a great chance for everybody: 
                        </div>
                        <br/>
                        <div className='page-p left-text'>
                            <ul><li>for streamers to show their favorite location of their native cities, countries.
                            </li><li>for viewers to see the locations they want to see, visit.
                            </li></ul>
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='title_1'>
                        Platform’s description and main recommendations for viewers
                        </div>
                    </div>
                    <div className='row'>
                        <div className='secondary-title left-text'>
                        Platform’s description
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'>
                            <ol><li>You can watch streams without any registration, 
                                free of charge, but if you want you can reward by donation
                                to your favorite streamer (the function is not available at
                                first stage of platform development).
                            </li><li>You can make comments during the stream, this function is available for registered viewers, as donations function (at first platform’s development is not available).
                            </li><li>You can share the live video stream link, before the stream starting or even at the time of live streaming, to show your friends the places your like or want to see.
                            </li></ol>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='secondary-title left-text'>
                        Recommendations
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='page-p left-text'> 
                            <ol><li>Please be polite, when make comments and also follow the platform’s commitments: 
                                <ul><li>NO harmful content
                                </li><li>NO hate
                                </li><li>NO misinformation
                                </li><li>NO extremist content
                                </li><li>NO bias
                                </li><li>NO politics
                                </li></ul>
                            </li><li>Share the streams which you like - more sharing - more viewers -more interesting live video streams from different locations.
                            </li></ol>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='page-p left-text'>
                        All together we will make the best live stream travel platform – Livevoyage.club. We have a great plan for the platform's development, but we need your support, we make only the first steps!
                        </div>
                    </div>
                </div>
            </div>
    </ContentWrapper>
    );
};

export default About;
