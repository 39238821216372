import React from 'react';
import logo from '../logo.svg';
import google from '../assets/googleplay.svg';
import apple from '../assets/apple.svg';
import { HumburgerIcon, CloseMiniIcon } from '../components/icons/Icons';
import { SearchIcon } from './icons/Icons';
import LButton from './button';

import { checkLoggenIn, logout } from '../livevoyage/api_client';
import {useNavigate } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';

const Header = ({onSubmitHandler, handleSearchChange, active_search_bar}) => {
  const navigate = useNavigate ();
  const [loggedin, setloggedin] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  let update = false;
  let login = false;
  const loginClick = () =>{
    if(loggedin){
      logout();
      login = false;
      setloggedin(false);
      navigate('/');
    }
    else {
      navigate('/login');
    }
  }
  const submi = (e) =>{
    e.preventDefault();
    onSubmitHandler(e);
  }

  const signUpClick = () =>{
    if(loggedin){
      navigate('/profile');
    }
    else {
      navigate('/sign-up');
    }
  }

  const logoClick = () =>{
      navigate('/');
  }
  useEffect(()=>{
    let temp =  checkLoggenIn();
    update= temp != loggedin;

    if(update){
      login = temp;
      setloggedin(temp);
    }
  },[update]);

  return (

      <nav className='header blur '>
        <div className='navigation'>
          <img src={logo} alt="logo" onClick={logoClick}/>
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" 
              : "navigation-menu l_nav_m"
            }
          >
            <ul>{active_search_bar && <li className='l_nav_m'><form onSubmit={onSubmitHandler} className='l_search_bar l_nav_m'>
                  <input className='l_search_input l_nav_m' placeholder="Search" type="text" name="key"
                    onChange={handleSearchChange}></input>
                  <button className='l_search_button' type="submit"> <SearchIcon/></button>
              </form></li>}
              <li><div className='cust-but'>
                <a target="_blank"  href='https://play.google.com/store/apps/details?id=com.livevoyage.livevoyager'>
                  <img src={apple} height='45'/>
                  </a>
              </div></li>
              <li><div className='cust-but'>
                <a target="_blank"  href='https://play.google.com/store/apps/details?id=com.livevoyage.livevoyager'>
                  <img src={google} height='45'/>
                  </a>
              </div></li>
              <li onClick={loginClick}><LButton type = {2} >
                {loggedin  ? "Logout": "Login"}
              </LButton></li>
              <li onClick={signUpClick}><LButton type = {1} >
                {loggedin? "Profile": "Sign Up"}
              </LButton></li>
            </ul>
          </div>
          <button className="hamburger " onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}>
            {isNavExpanded ? <CloseMiniIcon/>: <HumburgerIcon/>}
          </button>
        </div>
      </nav>
  );
};

export default Header;
