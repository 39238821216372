import React, {useEffect, useState} from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import '../App.css';
import asia from '../images/asia.png'
import europe from '../images/europe.png'
import africa from '../images/africa.png'
import latin from '../images/latin.png'
import north from '../images/north.png'
import ic_avatar from "../assets/avatar.png"

import background from "../assets/stream.png"

import Stream from '../components-v2/stream/stream';
import ContentWrapper from '../components-v2/contentWrapper';
import Continent from '../components-v2/stream/continent';

import {getStreams, searchUsers} from "../livevoyage/api_client";
import { CloseIconModal} from '../components/icons/Icons';
import{JoinIcon, UnJoinIcon, SearchIcon, 
      LocationIcon, LikeIcon, LikedIcon, ViewIcon} from '../components-v2/icons/Icons';
import UserCard from '../components/stream/userCard';

import LButton from '../components-v2/button';

function HomePage() {

  const navigate = useNavigate();
  const location = useLocation();

  const [open_Detail, setOpenDetail] = useState(false);
  const [stream_Detail, setStreamDetail] = useState(null);
  const [continents, setContinents] = useState([]);
  const [selectedContinent, setSelectedContinent] = useState(null);
  const [data_states, setDataStates] = useState({
    upcming: true,
    live: true,
    saved: true
  });
  const [streams_upcoming, setUpcoming] = useState({
    streams:[],
    prev: null,
    next: null
  });
  const [streams_live, setLive] = useState({
    streams:[],
    prev: null,
    next: null
  });
  const [streams_saved, setSaved] = useState({
    streams:[],
    prev: null,
    next: null
  });
  
  const [is_search, setIsSearch] = useState(false);
  const [form, setForm] = useState({
    key: "",  
  });
  const[searchedUsers, setsearchedUsers] = useState({
    users:[],
    prev: null,
    next: null
  });
  const [searchedStreams, setsearchedStreams] = useState({
    streams:[],
    prev: null,
    next: null
  });

  const selecteddate = null;

  useEffect(() => {
    setContinents(getContinents());
    updateFeed();
  },[selecteddate, selectedContinent])

  const updateFeed=()=>{
    loadUpcoming(false);
    loadLive(false);
    loadSaved(false);
  }
  const testclick = ()=>{
    console.log('testclick');
  }
  const loadUpcoming = async (append) =>{
    let q = "";
    if(append){
      if(streams_upcoming.next == "") return;
      q = streams_upcoming.next;
    }
    const streams = await getStreams(1, selecteddate, selectedContinent, q);
    let str = append? streams_upcoming.streams.concat(streams.streams):streams.streams;
    let prev = streams.prev;
    let next = streams.next;
    setUpcoming({streams: str,prev, next });
  }
  
  const loadLive = async (append) =>{
    let q = "";
    if(append){
      if(streams_live.next == "") return;
      q = streams_live.next;
    }
    const streams = await getStreams(2, selecteddate,selectedContinent, q);
    let str = append? streams_live.streams.concat(streams.streams):streams.streams;
    let prev = streams.prev;
    let next = streams.next;
    setLive({streams:str,prev, next });
  }
  const loadSaved = async (append) =>{
    let q = "";
    if(append){
      if(streams_saved.next == "") return;
      q = streams_saved.next;
    }
    const streams = await getStreams(3, selecteddate, selectedContinent, q);
    let str = append? streams_saved.streams.concat(streams.streams):streams.streams;
    let prev = streams.prev;
    let next = streams.next;
    setSaved({streams:str, prev, next });
  }


  const upcoming_listener = async () =>{
    setLive({ streams:[], prev: null, next: null});
    setSaved({ streams:[], prev: null, next: null});
    loadUpcoming(true);
  };
  const live_listener = async () =>{
    setUpcoming({ streams:[], prev: null, next: null});
    setSaved({ streams:[], prev: null, next: null});
    loadLive(true);
  };
  const saved_listener = async () =>{
    setLive({ streams:[], prev: null, next: null});
    setUpcoming({ streams:[], prev: null, next: null});
    loadSaved(true);
  };

  const openDetail = (stream) => {
    navigate('/stream/'+ stream.id, {state: {from:'/'}});
  }

    const date_options = { year: 'numeric', month: 'long', day: 'numeric' };
    const time_options = {hour: '2-digit',minute: '2-digit'};
    const looper = [1,];
  const getContinents = ()=>{
    
    let cont = []
    cont[0] = {name:"Asia", description: "", image: asia, code: 'AS', live: false };
    cont[1] = {name:"Europe", description: "", image: europe, code: 'EU', live: true };
    cont[2] = {name:"Africa", description: "", image: africa, code: 'AF' , live: true };
    cont[3] = {name:"Latin America", description: "", image: latin, code: 'SA' , live: false };
    cont[4] = {name:"North America", description: "", image: north, code: 'NA' , live: false };
    return cont;
  }
  const selectContinent = (cont) =>{
    setSelectedContinent(cont);
  }
  const clearContinent = async ()=>{
    setSelectedContinent(null);
  }

  const handleSearchChange = ({ target : { value, name } }) => setForm({...form, [name]: value})
  const handleChange = async (value) => {
    if(value != null && value != ""){
      setIsSearch(true);
      const users = await searchUsers(value, "");

      const streams = await getStreams(4, null, null, "", null, value);

      setsearchedUsers({users:users.users, prev:users.prev, next:users.next});
      setsearchedStreams({streams:streams.streams, prev:streams.prev, next:streams.next});
    }else setIsSearch(false);
  }
  
  const onSubmitHandler = (event) => {
    event.preventDefault();
    handleChange(form.key);
  }

  return (
    <ContentWrapper onSubmitHandler={onSubmitHandler} handleSearchChange={handleSearchChange}
          active_search_bar={true}>
      <div className='l_content'>
        <div className='side_bar_main'>
          <div className='heading2'>
            Watch from
          </div>
          
        <div className='side_bar'>
            {selectedContinent != null ? 
              <div>
                <Continent continent = {selectedContinent}/>
                <div onClick={clearContinent}><CloseIconModal /></div>
              </div>
              :<>
                {continents.map(x => (
                  <div onClick={()=>selectContinent(x)}><Continent continent = {x} key={x.code} /></div>
                ))}
              </>
            }
          </div>
        </div>

        <div className='main_content'>
          <div className='heading1'>Discover a Vibrant World of a Real-Time Travel Streaming </div>
                    
          {is_search ?
            <div className='stream_section'>
              <div className='stream_section_title'>
                <div className='heading2'>Search Results</div>
              </div>
              <div className='section'>
                <div className='streams'>
                  <ul className="grid">
                    {searchedUsers.users.map(x => (
                      <li className='grid-item'> <UserCard user = {x} key={x.id}/></li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='stream_grid'>
                {searchedStreams.streams.map(x=>(
                  <Stream stream = {x} key={x.id} onclick = {openDetail}/>
                ))}
              </div>
            </div>
            :<>
              { streams_upcoming.streams && streams_upcoming.streams.length > 0 &&
                <div className='stream_section'>
                  <div className='stream_section_title'>
                    <div className='heading2'>Upcoming streams</div>
                    <LButton type ={4}>All</LButton>
                  </div>
                  <div className='stream_grid'>
                    {streams_upcoming.streams.map(x => (
                      <Stream stream = {x} key={x.id} onclick = {openDetail}/>
                    ))}
                  </div>
                  <div onClick={() => loadUpcoming(true)}><LButton type={1}>Load more</LButton></div>
                </div>
              }
              { streams_live.streams && streams_live.streams.length > 0 && 
                <div className='stream_section'>
                  <div className='stream_section_title'>
                    <div className='heading2'>Live streams</div>
                    <LButton type ={4}>All</LButton>
                  </div>
                  <div className='stream_grid'>
                    {streams_live.streams.map(x => (
                      <Stream stream = {x} key={x.id} onclick = {openDetail}/>
                    ))}
                  </div>
                  <div onClick={() => loadLive(true)}><LButton type={1}>Load more</LButton></div>
                </div>
              }
              { streams_saved.streams && streams_saved.streams.length > 0 &&
                <div className='stream_section'>
                  <div className='stream_section_title'>
                    <div className='heading2'>Passed streams</div>
                    <LButton type ={4}>All</LButton>
                  </div>
                  <div className='stream_grid'>
                    {streams_saved.streams.map(x => (
                      <Stream stream = {x} key={x.id} onclick = {openDetail}/>
                    ))}
                  </div>
                  <div onClick={() => loadSaved(true)}><LButton type={1}>Load more</LButton></div>
                </div>
              }
            </>
          }
        </div>
      </div>
    </ContentWrapper>
  );
}

export default HomePage;
