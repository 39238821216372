
import axios from "axios";

import config from '../config/config';
import { v4 as uuidv4 } from 'uuid';

const local = config.http.host+"/api";
const prodip = "https://livevoyage.club/api";

const xAccessTokenHeader = () =>{
    let header = {};
    if(localStorage.getItem("token")) {
        header["x-access-token"] = localStorage.getItem("token");
    }
    return header;
};
const instance = axios.create({
    baseURL: local,
})

instance.interceptors.request.use(
    config => {
        if(localStorage.getItem("token")) {
            config.headers["x-access-token"] = localStorage.getItem("token");
        }
        
        if(!localStorage.getItem("appToken")) {
            localStorage.setItem('appToken', uuidv4());
        }
        config.headers["app-access-token"] = localStorage.getItem("appToken");

        return config;
    },
    error => {
        return Promise.reject(error)
    }
)

export const checkLoggenIn = () => {
    return localStorage.getItem('token') != null;
}

export const login = async (body) => {
    let data = await clientLogin(body);
    if(data){
        localStorage.setItem('token', data.accessToken);
        let user = await getMe();
        return true;
    }
    else return false;
}
const clientLogin = (body) => {
    return instance.post("/auth/signin", body)
        .then(({ data }) => data)
        .catch(({error}) => error);
}
const clientRegister = (body) => {
    return instance.post("/auth/signup", body)
        .then(({ data }) => data)
        .catch(({error}) => error);
}
export const clientDeleteUser = (id) => {
    return instance.delete("/user/delete/"+id)
        .then(({ data }) => data)
        .catch(({error}) => error);
}

export const logout = () => {
    localStorage.clear();
}

export const resetPassword = async(email) => {
    return instance.get("/reset-password/"+email)
        .then(({ data }) => data)
        .catch(({error}) => error);
}

export const updatePassword = async(id, token, body) => {
    return instance.post("/update-password/"+id+"/"+token, body)
        .then(({ data }) => data)
        .catch(({error}) => error);
}

export const verfyEmail = async(id, token) => {
    return instance.get("/email-verify/"+id+"/"+token)
        .then(({ data }) => data)
        .catch(({error}) => error);
}

export const verifyEmail = async(id, token) => {
    return instance.post("/update-password/"+id+"/"+token)
        .then(({ data }) => data)
        .catch(({error}) => error);
}

export const register = async(body) => {
    let data = await clientRegister(body);
    if(data){
        return true;
    }
    else return false;
}


export const updateUser = (body) => {
    let header = xAccessTokenHeader();
    header["Content-Type"] = 'multipart/form-data;charset=utf-8; boundary=apiclient-'+Date.now();
    return instance.post("/users", body, {headers: header}).then(({ data }) => data)
}

export const getMe = () => {
    return instance.get("/users/me",{headers:xAccessTokenHeader()}).then(({ data }) => {
        localStorage.setItem('user', JSON.stringify(data.user));
        return data.user;
    });
}

export const getUser = (id) => {
    return instance.get("/users/"+id,{headers:xAccessTokenHeader()})
        .then(({ data }) => data.user);
}

export const follow = (id) => {
    return instance.post("/users/follow/"+id,{headers:xAccessTokenHeader()})
        .then(({ data }) => data.res);
}
export const unfollow = (id) => {
    return instance.post("/users/unfollow/"+id,{headers:xAccessTokenHeader()})
        .then(({ data }) => data.res);
}

export const getStreams = (state, date, continent, page, user = null, key="") => {
    //let query = "?state="+state;
    let query = "?date="+date;
    if(state != 4) query += "&state="+state;
    if(user != null) query += "&user="+user.id;
    if(continent != null) query += "&continent="+continent.code
    if(page != "") query += "&"+page;
    if(key != "") query += "&key="+key;
    return instance.get(`/streams/${query}`).then(({ data }) => data)
    //return instance.get(`/streams/${state}/${date}${q}`).then(({ data }) => data)
}

export const getStream = (id) => {
    return instance.get(`/stream/${id}`).then(({ data }) => data.stream)
}
export const loadLive = (stream, date) => {
    return instance.get(`/live/${stream.id}/${date}`).then(({ data }) => data)
}

export const sendComment = (id, body) => {
    return instance.post(`/streams/comment/${id}`, 
            body, {headers:xAccessTokenHeader()}
        ).then(({ data }) => data);
}

export const like = (id) => {
    return instance.post(`/streams/like/${id}`, {headers:xAccessTokenHeader()}
        ).then(({ data }) => data);
}

export const unlike = (id) => {
    return instance.post(`/streams/unlike/${id}`, {headers:xAccessTokenHeader()}
        ).then(({ data }) => data);
}

export const searchUsers = (key, page) => {
    var url = "/users/";
    var params = "";
    if(key != "") params += "key="+key;
    if(page != "") params += (params == ""?"":"&")+page;
    if(params != "") url += "?"+ params;

    return instance.get(`${url}`, {headers:xAccessTokenHeader()}
        ).then(({ data }) => data);
}