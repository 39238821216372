import React, {useEffect, useState} from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import '../App.css';
import asia from '../images/asia.png'
import europe from '../images/europe.png'
import africa from '../images/africa.png'
import latin from '../images/latin.png'
import north from '../images/north.png'
import Stream from '../components/stream/stream';
import ContentWrapper from '../components/content';

import {getStreams, searchUsers} from "../livevoyage/api_client";
import { CloseIconModal, SearchIcon } from '../components/icons/Icons';
import UserCard from '../components/stream/userCard';

function HomePage() {

  const navigate = useNavigate();
  const location = useLocation();

  const [open_Detail, setOpenDetail] = useState(false);
  const [stream_Detail, setStreamDetail] = useState(null);
  const [continents, setContinents] = useState([]);
  const [selectedContinent, setSelectedContinent] = useState(null);
  const [data_states, setDataStates] = useState({
    upcming: true,
    live: true,
    saved: true
  });
  const [streams_upcoming, setUpcoming] = useState({
    streams:[],
    prev: null,
    next: null
  });
  const [streams_live, setLive] = useState({
    streams:[],
    prev: null,
    next: null
  });
  const [streams_saved, setSaved] = useState({
    streams:[],
    prev: null,
    next: null
  });
  
  const [is_search, setIsSearch] = useState(false);
  const [form, setForm] = useState({
    key: "",  
  });
  const[searchedUsers, setsearchedUsers] = useState({
    users:[],
    prev: null,
    next: null
  });
  const [searchedStreams, setsearchedStreams] = useState({
    streams:[],
    prev: null,
    next: null
  });

  const selecteddate = null;

  useEffect(() => {
    setContinents(getContinents());
    updateFeed();
  },[selecteddate, selectedContinent])

  const updateFeed=()=>{
    loadUpcoming(false);
    loadLive(false);
    loadSaved(false);
  }

  const loadUpcoming = async (append) =>{
    let q = "";
    if(append){
      if(streams_upcoming.next == "") return;
      q = streams_upcoming.next;
    }
    const streams = await getStreams(1, selecteddate, selectedContinent, q);
    let str = append? streams_upcoming.streams.concat(streams.streams):streams.streams;
    let prev = streams.prev;
    let next = streams.next;
    setUpcoming({streams: str,prev, next });
  }
  
  const loadLive = async (append) =>{
    let q = "";
    if(append){
      if(streams_live.next == "") return;
      q = streams_live.next;
    }
    const streams = await getStreams(2, selecteddate,selectedContinent, q);
    let str = append? streams_live.streams.concat(streams.streams):streams.streams;
    let prev = streams.prev;
    let next = streams.next;
    setLive({streams:str,prev, next });
  }
  const loadSaved = async (append) =>{
    let q = "";
    if(append){
      if(streams_saved.next == "") return;
      q = streams_saved.next;
    }
    const streams = await getStreams(3, selecteddate, selectedContinent, q);
    let str = append? streams_saved.streams.concat(streams.streams):streams.streams;
    let prev = streams.prev;
    let next = streams.next;
    setSaved({streams:str, prev, next });
  }


  const upcoming_listener = async () =>{
    setLive({ streams:[], prev: null, next: null});
    setSaved({ streams:[], prev: null, next: null});
    loadUpcoming(true);
  };
  const live_listener = async () =>{
    setUpcoming({ streams:[], prev: null, next: null});
    setSaved({ streams:[], prev: null, next: null});
    loadLive(true);
  };
  const saved_listener = async () =>{
    setLive({ streams:[], prev: null, next: null});
    setUpcoming({ streams:[], prev: null, next: null});
    loadSaved(true);
  };

  const openDetail = (stream) => {
    navigate('/stream/'+ stream.id, {state: {from:'/'}});
  }

  const getContinents = ()=>{
    let cont = []
    cont[0] = {name:"Asia", description: "", image: asia, code: 'AS' };
    cont[1] = {name:"Europe", description: "", image: europe, code: 'EU' };
    cont[2] = {name:"Africa", description: "", image: africa, code: 'AF' };
    cont[3] = {name:"Latin America", description: "", image: latin, code: 'SA' };
    cont[4] = {name:"North America", description: "", image: north, code: 'NA' };
    return cont;
  }
  const selectContinent = (cont) =>{
    setSelectedContinent(cont);
  }
  const clearContinent = async ()=>{
    setSelectedContinent(null);
  }

  const handleSearchChange = ({ target : { value, name } }) => setForm({...form, [name]: value})
  const handleChange = async (value) => {
    if(value != null && value != ""){
      setIsSearch(true);
      const users = await searchUsers(value, "");

      const streams = await getStreams(4, null, null, "", null, value);

      setsearchedUsers({users:users.users, prev:users.prev, next:users.next});
      setsearchedStreams({streams:streams.streams, prev:streams.prev, next:streams.next});
    }else setIsSearch(false);
  }
  
  const onSubmitHandler = (event) => {
    event.preventDefault();
    handleChange(form.key);
  }
  return (
    <ContentWrapper>
        {
        /*<div className='left-items'>
          <div className="icon" onClick={upcoming_listener} >
            <img src={ic_upcoming} className="icon" alt="logo" />
          </div>
          <div className="icon" onClick={live_listener} >
            <img src={ic_live} className="icon" alt="logo" />
          </div>
          <div className="icon" onClick={saved_listener} >
            <img src={ic_saved} className="icon" alt="logo" />
          </div>
  </div>*/
        }
        <div className='content-header'>
          <div className='row main-title'>
            Welcome to the colorful world of live stream travelling!
          </div>

          <div className='continent'>
            <div className='row'>
              <div className='secondary-title'>
                Watch from
              </div>
            </div>
            
            <div className='row'>
              {selectedContinent != null ? 
                <div className='content-header-block'>
                  <div className='content-header-item'>
                    <div className="floatinline">
                      <div className='content-header-overlay'/>
                      <img className='content-header-image' src={selectedContinent.image}></img>
                    </div>
                    <div className='content-header-text'>{selectedContinent.name}</div>
                  </div>
                  <div onClick={clearContinent}>
                    <CloseIconModal />
                  </div>
                </div>
              :
                <div className='scrollable'>
                  <div className='content-header-block'>
                    {continents.map(x => (
                      <div className='content-header-item' key={x.code} onClick={()=>selectContinent(x)}>
                        <div>
                          <div className='content-header-overlay'/>
                          <div className='content-header-text'>{x.name}</div>
                          <img className='content-header-image' src={x.image}></img>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        
        <div className='section'><div className='centered'>
          
        <div className='custom-form'>
          <form onSubmit={onSubmitHandler}>
            <div className='inputline'>
              <input className='form-input form-text' width="500px"
                placeholder="Search" type="text" name="key"
                onChange={handleSearchChange}></input>
              <button className='def_button input-icon' type="submit">
                <SearchIcon/></button>
            </div>
          </form>
          </div>
        </div></div>
        {is_search ?
        <div>
          <div className='section'>
            <div className='row'>
              <div className='secondary-title'>
                Search
              </div>
            </div>
            <div className='section'>
              <div className='streams'>
                <ul className="grid">
                    {searchedUsers.users.map(x => (
                      <li className='grid-item'> <UserCard user = {x} key={x.id}/></li>
                    ))}
                </ul>
              </div>
            </div><div className='section'>
              <div className='streams'>
                <ul className="grid">
                    {searchedStreams.streams.map(x => (
                      <li className='grid-item'> <Stream stream = {x} key={x.id} onclick = {openDetail}/></li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>:<div>
        { streams_upcoming.streams && streams_upcoming.streams.length > 0 &&
          <div className='section'>
            <div className='row'>
              <div className='secondary-title'>
                Upcoming
              </div>
            </div>
            <div className='section'>
              <div className='streams'>
                <ul className="grid">
                    {streams_upcoming.streams.map(x => (
                      <li className='grid-item'> <Stream stream = {x} key={x.id} onclick = {openDetail}/></li>
                    ))}
                </ul>
              </div>
            </div>
            <center><button onClick={() => loadUpcoming(true)} className='botton header-item header-text'>More</button></center> 
          </div>
        }
        { streams_live.streams && streams_live.streams.length > 0 && 
          <div className='section'>
            <div className='row'>
              <div className='secondary-title'>Live</div>
            </div>
            <div className='section'>
              <div className='streams'>
                <ul className="grid">
                    {streams_live.streams.map(x => (
                      <li className='grid-item'><Stream stream = {x} key={x.id} onclick = {openDetail}/></li>
                    ))}
                </ul>
              </div>
            </div>
            <center><button onClick={() => loadLive(true)} className='botton header-item header-text'>More</button></center> 
          </div>
        }
        
        { streams_saved.streams && streams_saved.streams.length > 0 &&
          <div className='section'>
            <div className='row'>
              <div className='secondary-title'>Passed</div>
            </div>
            <div className='section'>
              <div className='streams'>
                <ul className="grid">
                    {streams_saved.streams.map(x => (
                      <li className='grid-item'><Stream stream = {x} key={x.id} onclick = {openDetail}/></li>
                    ))}
                </ul>
              </div>
            </div>
            <center><button onClick={() => loadSaved(true)} className='botton header-item header-text'>More</button></center> 
          </div>
        }
        </div>}
    </ContentWrapper>
  );
}

export default HomePage;
