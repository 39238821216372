import React,  {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {getMe, checkLoggenIn, updateUser} from '../../livevoyage/api_client';
import def_avatar from "../../assets/def_avatar.png"
import config from '../../config/config';
import BackBotton from '../../components/icons/BackBotton';
import ContentWrapper from '../../components-v2/contentWrapper';

const ProfileEdit = ( ) => {
    let user = JSON.parse(localStorage.getItem("user"));
    const [form, setForm] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        image: '',
        email: user.email,
        pass: '',
        pass2: '',
    });
    let formset = false;

    const handleChange = ({ target : { value, name } }) => setForm({...form, [name]: value})
    const [error, setError] = useState("");
    const [preview, setPreview] = useState('');
    const [image, setImage] = useState(null);

    const navigate = useNavigate();
    const sendData = async() =>{
        if(validateForm()){

            const formData = new FormData();
           /* if(typeof form['image'] === 'string') {
                delete form['image'];
            }*/
            const arrData = Object.keys(form);
            for(let item of arrData) {
                if(form[item]) {
                    formData.append(item, form[item])
                }
            }
            
            let res = await updateUser(formData);
            if(res){
                setFormData();
            }else {
                setError("Something went wrong!");
            }
        }
    };

    const validateForm = () => {
        if(form.firstName == ""){
            setError("First name is required!");
            return false;
        }
        if(form.email == ""){
            setError("Email is required!");
            return false;
        }
        if(form.username == ""){
            setError("Username is required!");
            return false;
        }
        if(form.pass != "" && form.pass != form.pass2){
            setError("Passwords mismatch!");
            return false;
        }
        setError("");
        return true;
    }

    const handlePreview = (e) => {
        const file = e.target.files[0]
        const reader =   new FileReader()
        reader.onloadend = (e) => {
            setPreview(reader.result)
        }
        reader.readAsDataURL(file)
    }
    const setFormData = async ()=>{
        user = await getMe();
        setForm({
            firstName: user.firstName,
            lastName: user.lastName,
            username: user.username,
            image: null,
            email: user.email,
            pass: "",
            pass2: '',
        });
    }
    useEffect(() => {
        let temp =  checkLoggenIn();
        if(!temp){
            navigate('/');
            return;
        }
        setFormData();
        formset = true;
    },[formset]);

    return (
        <ContentWrapper>
            <div className='content-header2'>
            <BackBotton className="left_btn"/>
                <div className='centered'>
                        <div className='custom-form'> 
                            <div className='profile-form_image'>
                                <img className='round profile-form_image' src={preview || ((user.image!="") ? config.http.host+user.image:def_avatar)} alt=""/>
                                    <input type="file" name='upload' id='upload' onChange={(e) => {
                                        //setImage(e.target.files[0]);
                                        handlePreview(e)
                                        setForm({...form, image: e.target.files[0]})
                                        //setImage(e.target.files[0]);
                                        //imageSet(e);
                                    }}
                                />
                            </div>
                            <input className='form-input form-text' 
                                placeholder="First name" type="text" name="firstName"
                                value={form.firstName} onChange={handleChange}/>
                            <input className='form-input form-text' 
                                placeholder="Last name" type="text" name="lastName"
                                value={form.lastName} onChange={handleChange}/>
                            <input className='form-input form-text' 
                                placeholder="Email" type="email" name="email"
                                value={form.email} onChange={handleChange}/>
                            <input className='form-input form-text'
                                placeholder="Username" type="text" name="username"
                                value={form.username} onChange={handleChange}/>
                            <input className='form-input form-text' 
                                placeholder="Password (leave empty if you don't want to change!)" type="password" name="pass"
                                value={form.pass} onChange={handleChange}/>
                            <input className='form-input form-text' 
                                placeholder="Confirm password" type="password" name="pass2"
                                value={form.pass2} onChange={handleChange}/>

                            <div className='form-footer'>
                                {error!= "" &&<p className='error-message'>{error}</p>}
                                <button className='botton2 header-item header-text' onClick={()=>{sendData()}}>
                                    Save
                                </button>
                            
                                <div className='header-item header-text cust-but' onClick={()=>{navigate('/login')}}>
                                    Exit
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            
        </ContentWrapper>
    );
};

export default ProfileEdit;
