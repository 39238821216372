import React from 'react';
import background from "../../assets/stream.png"
import ic_join from "../../assets/ic_join.svg"
import ic_location from "../../assets/ic_location.svg"
import ic_time from "../../assets/ic_time.svg"
import ic_avatar from "../../assets/avatar.png"
import { useNavigate } from 'react-router-dom';
import StreamStates from '../../utils/streamStates'
import DropdownMenu from '../dropdownMenu';
import { EditIcon } from '../icons/Icons';


import config from '../../config/config';

const Stream = ({stream, owner = false, onclick }) => {
    const navigate = useNavigate();
    const menu_item = [
        {
            name: 'Edit',
            link: '/stream/edit/'
        }
    ]
    const onClickUser = (userId)=>{
        navigate('/profile/'+userId);
    }
    if(stream)
    return (
        <div className="stream-card" >
            <div className="stream-header">
                <div className="stream-user" onClick={()=>onClickUser(stream.User.id)}>
                    <img className='stream-user_image' src={(stream.User.image != "")
                        ?config.http.host+stream.User.image: ic_avatar}/>
                    <p className="stream-user_name ">{stream.User.fullName}</p>
                </div>
                {!owner && stream.state == StreamStates.Upcoming &&
                    <div>
                        <img className='stream-join' src={ic_join}/>
                    </div>
                }
                {owner && <div className='stream-join pointer' onClick={()=>{navigate('/stream/edit/'+stream.id)}}>
                    <EditIcon />
                </div>}
            </div>
            <div className='stream-image-overlay' onClick={()=>onclick(stream)}/>
            <img className='stream-image' src={(stream.image!="")
                ?config.http.host+stream.image: background}/>
            <div className='stream-content'>
                <div className='stream-details'>
                    { stream.Location && 
                        <div className='stream-detail-item'>
                            <img className='stream-join' src={ic_location}/>
                            <p className="stream-datail-text ">{stream.Location.formattedAddress}</p>
                        </div>
                    }
                    <div className='stream-detail-item'>
                        <img className='stream-join' src={ic_time}/>
                        <p className="stream-datail-text ">
                            {new Date(stream.planDate).toLocaleString()}
                        </p>
                    </div>
                </div>
                <div className='stream-details'>
                    <p className='stream-title'>{stream.name}</p>
                </div>
            </div>
        </div>
    );
};

export default Stream;
