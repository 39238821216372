import React from 'react';
import logo from '../logo.svg';
import google from '../assets/googleplay.svg';
import { checkLoggenIn, logout } from '../livevoyage/api_client';
import {useNavigate } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';

const Footer = () => {
  const navigate = useNavigate ();
  
  const aboutClick = () =>{
      navigate('/about');
  }

  const termsClick = () =>{
      navigate('/terms');
  }
  return (
      <div className='footer'>
        <div className='footer_content'>
          <div className='row'> 
            <div className='col2'>
              “ESMUS” Sp.z.o.o.<br/>
              Poland,  street Sranislawa Leszczynskiego 4/29<br/>
              Wroclaw, 50-078<br/>
              NIP: 8971911754<br/>
              Tel: + 48 503 45 33 45<br/>
              E-mail: info@esmus.pro<br/>
            </div>
            <div className='col2'>
              <div className='header-item footer_menu_item header-text cust-but' onClick={termsClick}>
                Terms of use
              </div>
              <div className='header-item footer_menu_item header-text cust-but' onClick={aboutClick}>
                About
              </div>
              <div className='footer_menu_item header-text cust-but'>
                <a target="_blank" href='https://play.google.com/store/apps/details?id=com.livevoyage.livevoyager'>
                  <img src={google} height='45'/>
                  </a>
              </div>
            </div>
          </div>
        </div>
        <div className='header-item header-text'>
          All rights reserved © 2022 . Livevoyage.club is registered trade mark, owned by Esmus Sp.z.o.o.
        </div>
      </div>
  );
};

export default Footer;
