import React,  {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {login, getMe, checkLoggenIn, register} from '../../livevoyage/api_client';

const Register = ( ) => {

    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        pass: '',
        pass2: '',
    });

    const validateForm = () => {
        if(form.firstName == ""){
            setError("First name is required!");
            return false;
        }
        if(form.email == ""){
            setError("Email is required!");
            return false;
        }
        if(form.username == ""){
            setError("Username is required!");
            return false;
        }
        if(form.pass == ""){
            setError("Password is required!");
            return false;
        }
        if(form.pass != form.pass2){
            setError("Passwords mismatch!");
            return false;
        }
        setError("");
        return true;
    }

    const handleChange = ({ target : { value, name } }) => setForm({...form, [name]: value})

    const [error, setError] = useState("");

    const navigate = useNavigate();

    const sendData = async() =>{
        if(validateForm()){
            let res = await register(form);
            if(res){
                navigate('/login');
            }else {
                setError("Something went wrong!");
            }
        }
    };

    useEffect(() => {
        let temp =  checkLoggenIn();
        if(temp){
            navigate('/');
        }
    });

    return (
        <div className='content-box'>
            <div className='centered'>
                <div className='custom-form'> 
                    <div className='form-title'>
                        Sign Up
                    </div>
                    <input className='form-input form-text' 
                        placeholder="First name" type="text" name="firstName"
                        value={form.firstName} onChange={handleChange}/>
                    <input className='form-input form-text' 
                        placeholder="Last name" type="text" name="lastName"
                        value={form.lastName} onChange={handleChange}/>
                    <input className='form-input form-text' 
                        placeholder="Email" type="email" name="email"
                        value={form.email} onChange={handleChange}/>
                    <input className='form-input form-text' 
                        placeholder="Username" type="text" name="username"
                        value={form.username} onChange={handleChange}/>
                    <input className='form-input form-text' 
                        placeholder="Password" type="password" name="pass"
                        value={form.pass} onChange={handleChange}/>
                    <input className='form-input form-text' 
                        placeholder="Confirm password" type="password" name="pass2"
                        value={form.pass2} onChange={handleChange}/>

                    <div className='form-footer'>
                        {error!= "" &&<p className='error-message'>{error}</p>}
                        <button className='botton2 header-item header-text' onClick={()=>{sendData()}}>
                            Register
                        </button>
                    
                        <div className='header-item header-text cust-but' onClick={()=>{navigate('/login')}}>
                            Login
                        </div>
                    </div>
                </div>
           </div>
        </div>
    );
};

export default Register;
