
import '../App.css';
import Header from './header'
import Footer from './footer';

function ContentWrapper(props) {
  return (
    <div className="app">
      <Header/>
      <div className='body_content'>
        {props.children}
      </div>
      <Footer/>
    </div>
  );
}

export default ContentWrapper;
