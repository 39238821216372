import { Routes, Route, BrowserRouter, Navigate, useNavigate} from "react-router-dom";
import HomePage from "./pages/HomePage";
import HomePage2 from "./pages/HomePage-v2";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ProfileEdit from "./pages/Profile/ProfileEdit";
import Profile from "./pages/Profile/Profile";
import ProfileDelete from "./pages/Profile/ProfileDelete";
import Stream from "./pages/Stream/Stream";
import EditStream from "./pages/Stream/EditStream";
import About from "./pages/About/About";
import Terms from './pages/About/Terms';
import Confidential from './pages/About/Confidential';
import ScrollToTop from './components/ScrollToTop';
import ResetPassword from "./pages/Auth/ResetPassword";
import UpdatePassword from "./pages/Auth/UpdatePassword";
import VerifyEmail from "./pages/Auth/VerifyEmail";

function App() {
/*
  useEffect(() => {
}, [])

useEffect(() => {
  window.scrollTo(0, 0)
}, [pathname])

  useEffect(() => {
    dispatch(getSpheresThunk())
  }, [dispatch])
  */
  return (
    <>
        <BrowserRouter>
        <ScrollToTop>
            <Routes>
            <Route path='/old' index element={<HomePage/>} />
              <Route path='/' index element={<HomePage2/>} />
              <Route path='/reset-password' element={<ResetPassword/>} />
              <Route path='/email-verify/:id/:token' element={<VerifyEmail/>} />
              <Route path='/update-password/:id/:token' element={<UpdatePassword/>} />
              <Route path='/login' element={<Login/>} />
              <Route path='/sign-up' element={<Register/>} />
              <Route path='/profile' element={<Profile/>}/>
              <Route path='/profile/:id' element={<Profile/>}/>
              <Route path='/profile/delete' element={<ProfileDelete/>}/>
              <Route path='/stream/:id' element={<Stream/>}/>
              <Route path='/stream/edit/:id' element={<EditStream/>}/>
              <Route path='/profile/edit' element={<ProfileEdit/>} />
              <Route path='/about' element={<About/>} />
              <Route path='/terms' element={<Terms/>} />
              <Route path='/terms-conf' element={<Confidential/>} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
    </>
  );
}

export default App;
