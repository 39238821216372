import React,  {useEffect, useState} from 'react';

import ContentWrapper from '../../components-v2/contentWrapper';


const Confidential = ( ) => {
    return (
        
    <ContentWrapper>

    <div className='page-content'>
                <div className='section top-100'>
                    <div className='row'>
                        <div className='title_1'>
                            Data Policy
                        </div>
                        <div class="italic-text left-text">Last Updated: 05.03.2024</div>
                    </div>
                    <div className='row'>
                        <div className='secondary-title left-text'>
                        1. Introduction:
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'> 
                        Welcome to Livevoyage.club, a unique streaming platform specializing in showcasing real-time
travel experiences, cultural events, traditions, and more from different countries and cities
worldwide. This Data Policy outlines how we collect, use, share, and protect your data when you
use our services. By accessing or using Livevoyage.club, you agree to the terms of this Data
Policy.
                        </div>
                    </div>
                    <div className='row'>
                        <div className='secondary-title left-text'>
                        2. Information we collect:
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'> 
                            2.1. <strong> User-Provided Information:</strong> When you register on Livevoyage.club, we collect essential
                            information such as your name and email address to enhance your streaming experience.
                            <br/><br/>
                            2.2. <strong>Streaming Content:</strong> As a platform focused on live travel streams, we collect and store data
                            related to the content you share, including videos, location details, and descriptions.
                            <br/><br/>
                            2.3. <strong>Multicultural Communications: </strong>To facilitate multicultural communications, we may collect
                            information about traditions, national cuisine, and cultural events showcased in your live video
                            streams.
                        </div>
                    </div>
                    <div className='row'>
                        <div className='secondary-title left-text'>
                        3. How we use your information:
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'> 
                            3.1. <strong> Enhancing User Experience:</strong> We use the collected data to personalize your experience,
improve our services, and provide you with content that aligns with your interests in global travel
and cultural exploration.
<br/><br/>
                            3.2. <strong>Communication:</strong> We may use your email address to send important updates, notifications
about new streams, and other relevant information.<br/><br/>
                            3.3. <strong>Platform Optimization: </strong>Aggregated and anonymized data may be used for analytical
purposes to optimize platform performance and enhance features.
                        </div>
                    </div>

                    <div className='row'>
                        <div className='secondary-title left-text'>
                        4. Sharing your information:
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'> 
                            4.1. <strong>Promoting Cultural Exchange:</strong> Livevoyage.club is designed to promote multicultural
communications. Therefore, we may share your streaming content to allow users to explore and
appreciate different cultures and experiences.
<br/><br/>
                            4.2. <strong>Third-Party Service Providers:</strong> We may share your information with third-party service
providers to assist us in delivering and improving our services.
<br/><br/>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='secondary-title left-text'>
                        5. Your choices:
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'> 
                            5.1. <strong>Account Settings:</strong> You have the option to manage your account settings, including privacy
preferences and notification preferences.
<br/><br/>
                            5.2. <strong>Content Control:</strong> You can control the content you share during your live streams, ensuring it
aligns with our community guidelines.

<br/><br/>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='secondary-title left-text'>
                        6. Security:
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'> 
                        We implement security measures to protect your information and ensure a safe and enjoyable
streaming environment. However, no method of transmission over the internet or electronic
storage is completely secure.
                        </div>
                    </div>

                    <div className='row'>
                        <div className='secondary-title left-text'>
                        7. Changes to this data policy:
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'> 
                        We may update this Data Policy to reflect changes in our practices. We will notify you of any
significant changes through the platform or other communication channels.
                        </div>
                    </div>

                    <div className='row'>
                        <div className='secondary-title left-text'>
                        8. Contact us:
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'> 
                        If you have any questions, concerns, or requests regarding your data and privacy, please contact
us at info@esmus.pro or evskr1189@gmail.com
<br/><br/>
By using Livevoyage.club, you acknowledge and consent to the terms outlined in this Data Policy.
Thank you for being part of our global exploration community!
                        </div>
                    </div>
                </div>
            </div>
    </ContentWrapper>
    );
};

export default Confidential;
