import React,  {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {login, getMe, checkLoggenIn} from '../../livevoyage/api_client';

const Login = () => {
    const [usern, setusern] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const navigate = useNavigate ();

    const handleUsername =(event) => {
        setusern(event.target.value);
    }      
    const handlePassword = (event) =>{
        setPassword(event.target.value);
    }

    const sendData = async() =>{        
        if(usern == ""){
            setError("Username is required!");
            return;
        }
        if(password == "") {
            setError("Password is required!");
            return;
        }
        setError("");
        let body = {
            username: usern,
            pass: password
        };
        let res = await login(body);
        if(res){
            navigate('/');
        }else {
            setError("Username or Password incorrect!");
        }
    }

    useEffect(() => {
        let temp =  checkLoggenIn();
        if(temp){
            navigate('/');
        }
    })

    return (
        <div className='content-box'>
            <div className='centered'>
                <div className='custom-form'> 
                    <div className='form-title'>
                        Login
                    </div>
                    <input className='form-input form-text' 
                        placeholder="Username" type="text" name="username"
                        value={usern.value} onChange={handleUsername}/>
                    <input className='form-input form-text' 
                        placeholder="Password" type="password" name="password"
                        value={password.value} onChange={handlePassword}/>
                    <div className='form-footer'>
                        {error!= "" &&<p className='error-message'>{error}</p>}
                        <button className='botton2 header-item header-text' onClick={()=>{sendData()}}>
                            Login
                        </button>
                    
                        <div className='header-item header-text cust-but' onClick={()=>{navigate('/sign-up')}}>
                            Register
                        </div>
                    </div>
                </div>
           </div>
        </div>
    );
};

export default Login;
