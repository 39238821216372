
import {loadLive} from "../../livevoyage/api_client";
import StreamStates from "../../utils/streamStates";

class TextStreaming {

    constructor(stream, last){
        this.stream = stream;
        this.last = last;
        this.play = false;
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    async run (onNewComment, onNewLikes, onNewView, onNewAdditions, onEnd){
        this.play = true;
        while(this.play && (this.stream.state === StreamStates.Live || this.stream.state === StreamStates.Saved))
        {
            let data = await loadLive(this.stream, this.last);
            if(data){
                onNewLikes(data.likes);
                if (data.comments && data.comments.length > 0) {
                    this.last = data.comments[data.comments.length - 1].createdAt;
                    onNewComment(data.comments);
                }
                this.stream.state = data.state;
                onNewView(data.views);
                onNewAdditions(data.additionalStreams);
                await this.sleep(3000);
            } else {
                onEnd();
                return;
            }
        }
    }
    stop(){
        this.play = false;
    }
};

export default TextStreaming;
