import React from 'react';
import logo from '../logo.svg';
import google from '../assets/googleplay.svg';
import { HumburgerIcon, CloseMiniIcon } from './icons/Icons';
import { checkLoggenIn, logout } from '../livevoyage/api_client';
import {useNavigate } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';

const Header = () => {
  const navigate = useNavigate ();
  const [loggedin, setloggedin] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  let update = false;
  let login = false;
  const loginClick = () =>{
    if(loggedin){
      logout();
      login = false;
      setloggedin(false);
    }
    else {
      navigate('/login');
    }
  }

  const signUpClick = () =>{
    if(loggedin){
      navigate('/profile');
    }
    else {
      navigate('/sign-up');
    }
  }

  const logoClick = () =>{
      navigate('/');
  }
  useEffect(()=>{
    let temp =  checkLoggenIn();
    update= temp != loggedin;

    if(update){
      login = temp;
      setloggedin(temp);
    }
  },[update]);

  return (
      <nav className='header blur navigation'>
        <img src={logo} className="App-logo" alt="logo" onClick={logoClick}/>
        <button className="hamburger " onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}>
          {isNavExpanded ? <CloseMiniIcon/>: <HumburgerIcon/>}
        </button>
        <div
          className={
            isNavExpanded ? "navigation-menu footer_menu_item expanded" 
            : "navigation-menu footer_menu_item"
          }
        >
          <ul>
            <li><div className='header-text cust-but'>
              <a target="_blank"  href='https://play.google.com/store/apps/details?id=com.livevoyage.livevoyager'>
                <img src={google} height='45'/>
                </a>
            </div></li>
            <li><div className='header-item header-text cust-but ' onClick={loginClick}>
              {loggedin  ? "Logout": "Login"}
            </div></li>
            <li><button className='botton header-item header-text ' onClick={signUpClick}>
              {loggedin? "Profile": "Sign Up"} 
            </button></li>
          </ul>
        </div>
      </nav>
  );
};

export default Header;
