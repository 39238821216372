import React from 'react';
import background from "../../assets/stream.png"
import ic_join from "../../assets/ic_join.svg"
import ic_location from "../../assets/ic_location.svg"
import ic_time from "../../assets/ic_time.svg"
import ic_avatar from "../../assets/avatar.png"
import { useNavigate } from 'react-router-dom';
import StreamStates from '../../utils/streamStates'
import DropdownMenu from '../../components/dropdownMenu';
import {JoinIcon, UnJoinIcon, SearchIcon, 
    LocationIcon, LikeIcon, UnLikeIcon, LikedIcon, ViewIcon} from '../icons/Icons';

import config from '../../config/config';

const Stream = ({stream, owner = false, onclick }) => {
    const navigate = useNavigate();
    const date_options = { year: 'numeric', month: 'long', day: 'numeric' };
    const time_options = {hour: '2-digit',minute: '2-digit'};
    const streamDate = new Date(stream.planDate);
    const menu_item = [
        {
            name: 'Edit',
            link: '/stream/edit/'
        }
    ]
    const onClickUser = (userId)=>{
        navigate('/profile/'+userId);
    }
    if(stream)
    return (
        <div className='l_stream_card' onClick={()=>onclick(stream)}>
            <div className='l_stream_card_content'>
                <div className='l_stream_card_header'>
                <div className='l_stream_card_user' onClick={()=>onClickUser(stream.User.id)}>
                    <img className='l_stream_card_userimage' src={(stream.User.image != "")
                        ?config.http.host+stream.User.image: ic_avatar}/>
                    <div className='l_stream_card_username'>{stream.User.fullName}</div>
                </div>
                {!owner && stream.state == StreamStates.Upcoming && 
                    <div className='l_stream_card_indicator'>
                        <div className='l_stream_card_indicator_icon' >{stream.joined ? <UnJoinIcon />:<JoinIcon/>}</div>
                        <div className='l_stream_card_indicator_text'> {stream.joins} </div>
                    </div>
                }
                {stream.state == StreamStates.Live && 
                    <div className='l_stream_card_indicator'>
                        <div className='l_stream_card_liveindicator' >Live</div>
                        <div className='l_stream_card_indicator_text'> {stream.views} </div>
                    </div>
                }
                </div>
                <div className='l_stream_card_title'>{stream.name}</div>
                <div className='l_stream_card_detail'>
                {stream.Location && <div className='l_stream_card_detail_row'>
                    <div className='l_stream_card_detail_icon' ><LocationIcon/></div>
                    <div className='l_stream_card_detail_text' >{stream.Location.formattedAddress}</div>
                </div>}
                <div className='l_stream_card_detail_row'>
                    <div className='l_stream_card_detail_time'>
                    <div className='l_stream_card_detail_timetext'>{streamDate.toLocaleDateString('en-US', date_options)}</div>
                    <div className='l_stream_card_detail_timetext'>{streamDate.toLocaleTimeString('en-US', time_options)}</div>
                    </div>
                </div>
                </div>
                <div className='l_stream_card_footer'>
                <div className='l_stream_card_footer_item'>
                    <div className='l_stream_card_detail_icon' >{stream.liked?<LikedIcon />:<LikeIcon />}</div>
                    <div className='l_stream_card_indicator_text'>{stream.likes}</div>
                </div>
                <div className='l_stream_card_footer_item'>
                    <div className='l_stream_card_detail_icon' ><ViewIcon /></div>
                    <div className='l_stream_card_indicator_text'>{stream.views}</div>
                </div>
                </div>
            </div>
            <div className='l_stream_overlay'/>
            <img className='l_stream_image' src={(stream.image!="")
                ?config.http.host+stream.image: background}/>
        </div>
    );
};

export default Stream;
