import React, {useEffect, useState} from 'react';

import { useNavigate, useParams,useLocation } from 'react-router-dom';
import Stream from '../../components/stream/stream';
import StreamDetail from '../../components/stream/streamDetail';
import ContentWrapper from '../../components-v2/contentWrapper';
import BackBotton from '../../components/icons/BackBotton';

import {clientDeleteUser, logout} from '../../livevoyage/api_client';
import ic_avatar from "../../assets/avatar.png"

import config from '../../config/config';

function Profile () {
  
  const [user, setUser] = useState(null);
  let [isMe, setMe] = useState(false);
  let me = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const location = useLocation();

  const selecteddate = null;

  useEffect(() => {
    loadUser();
    /*if(temp){
      navigate('/login');
      return;
    }*/
  },[selecteddate])

  const loadUser = async () =>{
    let temp = null;
    if (me != null){
      setUser(me);
      temp = me;
      setMe(true);
    }
    return temp;
  }
  const deleteAccount = async ()=>{
    let res = await clientDeleteUser(user.id);
    if (res){
      logout();
      navigate('/');
    }
  }
  const go = (url)=>{
    navigate(url, {state:{from: location.pathname}});
  }
  return (
    <ContentWrapper>
      {user != null && <div>
      {/*<div className='left-items'>
        <div className="icon" onClick={upcoming_listener} >
          <img src={ic_upcoming} className="icon" alt="logo" />
        </div>
        <div className="icon" onClick={live_listener} >
          <img src={ic_live} className="icon" alt="logo" />
        </div>
        <div className="icon" onClick={saved_listener} >
          <img src={ic_saved} className="icon" alt="logo" />
        </div>
      </div>*/}

      <div className='content-header'>
      <BackBotton className="left_btn"/>
        <div className='continent'>
          <div className='lrow'>
            <div className='lcol'>
              <div className='content-header-block'>
                <div className='content-header-item2'>
                  <div>
                    <img className='content-header-image' 
                      src={(user.image != "" && user.image != null )
                      ?config.http.host+user.image: ic_avatar}></img>
                  </div>
                </div>
              </div>
            </div>
            <div className='lcol'>
              <div className='content-header-elem'>
                <div className='row'>
                  <div className='secondary-title left-align '>
                  {user.username}
                  </div>
                  <div className='profile-user_name left-align'>
                    {user.fullName}
                  </div>
                </div>
                <div className='lrow'>
                  
                  <div className='lcol'>
                    <div className='block_10'>
                      <button onClick={() => deleteAccount()} 
                        className='botton header-item header-text'>
                          Delete account.
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>}
    </ContentWrapper>
  );
}

export default Profile;
