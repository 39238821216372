import React, { Component, useRef, useEffect} from 'react';
import videojs from 'video.js'

import config from '../../config/config';
import StreamStates from '../../utils/streamStates';
import { PlayIcon, PauseIcon } from '../icons/Icons';
import background from "../../assets/stream.png"
 
 //http://192.168.1.7:8888/api/streams/live/edeb4bec-35b6-408a-a9c1-9cb85ed63fb0
export default class VideoPlayer extends React.Component {
 
    constructor(props) {
        super(props);
        this.autoplayRef = React.createRef();
        this.playClick = this.playClick.bind(this);
        this.playPause = this.playPause.bind(this);
        this.state = {
            url: props.url,
            stream: props.stream,
            endlistener: props.endlistener,
            videoRTMPOptions: null,
            videoOptions: null,
            play: false,
            isBottonVisible: true
        }
    }
    
    playClick(play){
        play? this.player.play():this.player.pause(); 
    }
    playPause(play){
        this.setState({
            play: play,
        });
    }

    componentDidMount() {
            this.setState({
                videoRTMPOptions: {
                    autoplay: true,
                    controls: true,
                    sources: [{
                        src: 'http://'+config.rtmp.hostname +':'+ config.rtmp.port + '/live/' + this.props.url + '/index.m3u8', //.flv /index.m3u8
                        type: 'application/x-mpegURL' //video/x-flv application/x-mpegURL
                    }],
                    fluid: true,
                    fillScreen: true
                },
                videoOptions: {
                    autoplay: true,
                    loop: true,
                    controls: true,
                   // muted: true,
                    webkitPlaysinline: true,
                    playsinline : true,
                    poster: (this.state.stream.image!="")?
                        config.http.host+this.state.stream.image: background,
                    sources: [{
                        src: config.http.host + '/api/vod/' + this.props.url,
                        type: 'video/mp4'
                    }],
                    responsive: true,
                    fluid: true,
                    fillScreen: true
                },
            }, () => {
                this.player = (this.state.stream.state == StreamStates.Live)?
                    videojs(this.videoNode, this.state.videoRTMPOptions, function onPlayerReady() {
                    }):
                    videojs(this.videoNode, this.state.videoOptions, function onPlayerReady() {
                    });
                this.player.on("ended", () => {
                    this.props.endlistener();
                });
                this.player.on("play", () => {
                    this.setState({play:true});
                });
                this.player.on("pause", () => {
                    this.setState({play:false});
                });
                this.player.on("useractive", () => {
                    this.setState({isBottonVisible:true});
                });
                this.player.on("userinactive", () => {
                    this.setState({isBottonVisible:false});
                });
            });
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.playerState.url !== this.props.playerState.url) {
            if(this.props.stream.state == StreamStates.Live)
                this.player.src({
                    src: 'http://'+config.rtmp.hostname +':'
                        + config.rtmp.port + '/live/' + this.props.playerState.url + '/index.m3u8',
                    type: 'application/x-mpegURL' //video/x-flv application/x-mpegURL
                });
            else {
                this.player.src({
                    src: config.http.host + '/api/vod/' + this.props.playerState.url,
                    type: 'video/mp4'
                });
                if(prevProps.playerState.start != null && this.props.playerState.start != null){
                    let curTime = this.player.currentTime();
                    let diff = (new Date(this.props.playerState.start) - new Date(prevProps.playerState.start))/1000;
                    if(curTime > diff)
                        this.player.currentTime(curTime - diff);
                }
            }
        }
    }
    render() {
        return (
            <div className="player">
                    {(this.state.stream.state == StreamStates.Live || 
                        this.state.stream.state == StreamStates.Saved) ? (
                        <div className='full'>
                            {// this.state.stream.state == StreamStates.Saved && 
                            (!this.state.play || this.state.isBottonVisible) && 
                                <>{this.state.play ? 
                                    <div className="overlay_btn ">
                                    <div className="center_mage"onClick={()=>{this.playClick(false);}}>
                                        <PauseIcon/>
                                    </div></div>:
                                    <div className="overlay_btn ">
                                    <div className="center_mage"onClick={()=>{this.playClick(true);}}>
                                        <PlayIcon/>
                                    </div></div>
                                }</>
                            }
                            <div data-vjs-player className='vjs-full-window'>
                                <video  ref={node => this.videoNode = node}
                                    className="video-js vjs-fullscreen  video-player"/>
                            </div>
                        </div>
                    ) : ' Loading ... '}
            </div>
        )
    }
}