import React,  {useEffect, useState} from 'react';

import config from '../../config/config';

import ContentWrapper from '../../components-v2/contentWrapper';


const About = ( ) => {
    
    return (
        
    <ContentWrapper>
            <div className='page-content'>
                <div className='section top-100'>
                    <div className='row'>
                        <div className='secondary-title left-text'>
                            About Livevoyage.club
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'>
                            Livevoyage.club is the streaming platform which specializes to show different countries, cities in real time by video bloggers and also to make multicultural communications by showing traditions, national cuisine and cultural events and so on from different countries around the world by live video streaming.
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-note left-text'>
                        The people will show to people the best things to see and to do in different countries, cities on the Livevoyage.club platform.    
                        </div>
                    </div>

                    <div className='row'>
                        <div className='secondary-title left-text'>
                            Our mission
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'>
                            <ul><li>To unify travel streamers, travelers and people who have an immense thirst for learning about the surrounding world.
                            </li><li>To visit interesting places without leaving your home, but see everything in real time.
                            </li><li>To help to choose which country to visit next time, what to visit in the city or country.
                            </li><li>To see everything in real time stream as you were here.
                            </li></ul>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-note left-text'>
                            We aim to provide our customers a top-rate experience before they will be in country or city in real life.
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='secondary-title left-text'>
                        Our solutions
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'>
                            Livevoyage.club allows for everyone:
                            <br/><br/>
                        </div>
                        <div className='page-p left-text'>
                            <ul><li>To offer people to see the place in real time and make decision to visit it or not.
                            </li><li>To get the first impression from the place that the people are going to visit not leaving their home.
                            </li><li>To promote the city, country through the platform.
                            </li><li>To make the city, country more attractable and interest for tourists.
                            </li></ul>
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='secondary-title left-text'>
                        Our solutions
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'>
                            <ul><li>NO harmful content
                            </li><li>NO hate
                            </li><li>NO misinformation
                            </li><li>NO extremist content
                            </li><li>NO bias
                            </li><li>NO politics
                            </li></ul>
                            <br/>
                            <ul><li>YES child safety
                            </li><li>YES user data protection
                            </li><li>YES revenue sharing
                            </li><li>YES digital wellbeing promoting

                            </li></ul>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='secondary-title left-text'>
                        Our business model
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-p left-text'>
                        Only live streams, only fair information. 
                        </div>
                        
                        <div className='page-p left-text'>
                        Get videos about the country, city or hotels around the world without any editing, as they are in a real life.
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    );
};

export default About;
