
import '../App.css';
import Header from './header'
import Footer from './footer';

function ContentWrapper({onSubmitHandler, handleSearchChange, active_search_bar, children}) {
  return (
    <div className="app">
      <Header onSubmitHandler={onSubmitHandler} handleSearchChange={handleSearchChange} active_search_bar={active_search_bar}/>
      <div className='body_content'>
        {children}
      </div>
      <Footer/>
    </div>
  );
}

export default ContentWrapper;
