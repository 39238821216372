import React, {useEffect, useState} from 'react';

import { useNavigate, useParams,useLocation } from 'react-router-dom';
import Stream from '../../components/stream/stream';
import StreamDetail from '../../components/stream/streamDetail';
import ContentWrapper from '../../components-v2/contentWrapper';
import BackBotton from '../../components/icons/BackBotton';

import {getStreams, getUser, follow, unfollow} from "../../livevoyage/api_client";
import ic_avatar from "../../assets/avatar.png"

import config from '../../config/config';

function Profile () {
  
  const { id } = useParams();
  const [user, setUser] = useState(null);
  let [isMe, setMe] = useState(false);
  let me = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const location = useLocation();

  const [open_Detail, setOpenDetail] = useState(false);
  const [stream_Detail, setStreamDetail] = useState(null);
  const [data_states, setDataStates] = useState({
    upcming: true,
    live: true,
    saved: true
  });
  const [streams_upcoming, setUpcoming] = useState({
    streams:[],
    prev: null,
    next: null
  });
  const [streams_live, setLive] = useState({
    streams:[],
    prev: null,
    next: null
  });
  const [streams_saved, setSaved] = useState({
    streams:[],
    prev: null,
    next: null
  });
  
  const selecteddate = null;

  useEffect(() => {
    loadUser(true);
    /*if(temp){
      navigate('/login');
      return;
    }*/
  },[selecteddate])

  const loadUser = async (loadcontemt) =>{
    let temp = null;
    if(id){
      temp = await getUser(id);
      console.log(temp);
      setUser(temp);
      
      if (me != null && temp.id == me.id) setMe(true);
    }
    else if (me != null){
      setUser(me);
      temp = me;
      setMe(true);
    }
    if(loadcontemt){
      loadUpcoming(temp, false);
      loadLive(temp, false);
      loadSaved(temp, false);
    }
    return temp;
  }
  const loadUpcoming = async (temp, append) =>{
    let q = "";
    if(append){
      if(streams_upcoming.next == "") return;
      q = streams_upcoming.next;
    }
    const streams = await getStreams(1, selecteddate, null, q, temp);
    let str = append? streams_upcoming.streams.concat(streams.streams):streams.streams;
    let prev = streams.prev;
    let next = streams.next;
    setUpcoming({streams: str,prev, next });
  }
  
  const loadLive = async (temp, append) =>{
    let q = "";
    if(append){
      if(streams_live.next == "") return;
      q = streams_live.next;
    }
    const streams = await getStreams(2, selecteddate,null,  q, temp);
    let str = append? streams_live.streams.concat(streams.streams):streams.streams;
    let prev = streams.prev;
    let next = streams.next;
    setLive({streams:str,prev, next });
  }
  const loadSaved = async (temp, append) =>{
    let q = "";
    if(append){
      if(streams_saved.next == "") return;
      q = streams_saved.next;
    }
    const streams = await getStreams(3, selecteddate,null,  q, temp);
    let str = append? streams_saved.streams.concat(streams.streams):streams.streams;
    let prev = streams.prev;
    let next = streams.next;
    setSaved({streams:str, prev, next });
  }

  const upcoming_listener = async () =>{
    setLive({ streams:[], prev: null, next: null});
    setSaved({ streams:[], prev: null, next: null});
    loadUpcoming(true);
  };

  const live_listener = async () =>{
    setUpcoming({ streams:[], prev: null, next: null});
    setSaved({ streams:[], prev: null, next: null});
    loadLive(true);
  };
  const saved_listener = async () =>{
    setLive({ streams:[], prev: null, next: null});
    setUpcoming({ streams:[], prev: null, next: null});
    loadSaved(true);
  };

  const openDetail = (stream) => {
    go('/stream/'+ stream.id);
  }
  const edit = ()=>{
    go('/profile/edit');
  }
  const go = (url)=>{
    navigate(url, {state:{from: location.pathname}});
  }
  const ufollow = async ()=>{
    let res = false;
    if(user.following == 0){
      res = await follow(user.id);
    }
    else res = await unfollow(user.id);
    if(res)
      loadUser(false);
  }
  return (
    <ContentWrapper>
      {user != null && <div>
      {/*<div className='left-items'>
        <div className="icon" onClick={upcoming_listener} >
          <img src={ic_upcoming} className="icon" alt="logo" />
        </div>
        <div className="icon" onClick={live_listener} >
          <img src={ic_live} className="icon" alt="logo" />
        </div>
        <div className="icon" onClick={saved_listener} >
          <img src={ic_saved} className="icon" alt="logo" />
        </div>
      </div>*/}

      <div className='content-header'>
      <BackBotton className="left_btn"/>
        <div className='continent'>
          <div className='lrow'>
            <div className='lcol'>
              <div className='content-header-block'>
                <div className='content-header-item2'>
                  <div>
                    <img className='content-header-image' 
                      src={(user.image != "" && user.image != null )
                      ?config.http.host+user.image: ic_avatar}></img>
                  </div>
                </div>
              </div>
            </div>
            <div className='lcol'>
              <div className='content-header-elem'>
                <div className='row'>
                  <div className='secondary-title left-align '>
                  {user.username}
                  </div>
                  <div className='profile-user_name left-align'>
                    {user.fullName}
                  </div>
                </div>
                <div className='lrow'>
                  <div className='lcol'>
                    <div className='block_10'>
                      <div className='foll_text'>
                      Followers
                      </div>
                      <div className='foll_val '>
                        {user.followers}
                      </div>
                    </div>
                  </div>
                  <div className='lcol '>
                    <div className='block_10'>
                      <div className='foll_text '>
                      Followings
                      </div>
                      <div className='foll_val'>
                        {user.followings}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='lrow'>
                  {isMe ? 
                    <div className='lcol'>
                      <div className='block_10'>
                        <button onClick={() => edit()} 
                          className='botton header-item header-text'>
                            Edit
                        </button>
                      </div>
                    </div>
                    :
                    <div className='lcol'>
                      <div className='block_10'>
                        <button onClick={() => ufollow()} 
                          className='botton header-item header-text'>
                            {user.following == 0?"Follow":"Unfollow"}
                        </button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      { streams_upcoming.streams && streams_upcoming.streams.length > 0 &&
        <div className='section'>
          <div className='row'>
            <div className='secondary-title'>
              Upcoming
            </div>
          </div>
          <div className='section'>
            <div className='streams'>
              <ul className="grid">
                {streams_upcoming.streams.map(x => (
                  <li className='grid-item'>
                    <Stream stream = {x} owner={isMe} key={x.id} onclick = {openDetail}/>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {streams_upcoming.next != "" && 
            <center>
              <button onClick={() => loadUpcoming(user, true)} 
                className='botton header-item header-text'>More</button>
            </center>
          } 
        </div>
      }
      { streams_live.streams && streams_live.streams.length > 0 && 
        <div className='section'>
          <div className='row'>
            <div className='secondary-title'>Live</div>
          </div>
          <div className='section'>
            <div className='streams'>
              <ul className="grid">
                  {streams_live.streams.map(x => (
                    <li className='grid-item'>
                      <Stream stream = {x} owner={isMe}  key={x.id} onclick = {openDetail}/>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {streams_live.next != "" && 
            <center>
              <button onClick={() => loadLive(user, true)} 
                className='botton header-item header-text'>More</button>
            </center>
          } 
        </div>
      }
      
      { streams_saved.streams && streams_saved.streams.length > 0 &&
        <div className='section'>
          <div className='row'>
            <div className='secondary-title'>Passed</div>
          </div>
          <div className='section'>
            <div className='streams'>
              <ul className="grid">
                {streams_saved.streams.map(x => (
                  <li className='grid-item'>
                    <Stream owner = {isMe} stream = {x} key={x.id} onclick = {openDetail}/>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {streams_saved.next !="" && 
            <center>
              <button onClick={() => loadSaved(user, true)} 
                className='botton header-item header-text'>More</button>
            </center>
          } 
        </div>
      }
    </div>}
    </ContentWrapper>
  );
}

export default Profile;
