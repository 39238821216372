import React,  {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {updatePassword} from '../../livevoyage/api_client';

const UpdatePassword = () => {
    const { id, token } = useParams()

    const [pass1, setPass1] = useState("");
    const [pass2, setPass2] = useState("");
    const [error, setError] = useState("");
    const [resultMess, setResultMess] = useState("");

    const navigate = useNavigate ();

    const handlePass1 =(event) => {
        setPass1(event.target.value);
    }
    const handlePass2 =(event) => {
        setPass2(event.target.value);
    }

    const sendData = async() =>{
        if(pass1 == ""){
            setError("Password is required!");
            return;
        }
        if(pass2 != pass1){
            setError("Passwords not match!");
            return;
        }
        setError("");
        
        let body = {
            pass1: pass1,
            pass2: pass2
        };

        let res = await updatePassword(id, token, body);
        if(res){
            setResultMess(res.message)
        }else {
            setError("Invalid link!");
        }
    }

    useEffect(() => {/*
        let temp =  checkLoggenIn();
        if(temp){
            navigate('/');
        }*/
    })
    return (
        <div className='content-box'>
            <div className='centered'>
                {resultMess == "" ? 
                <div className='custom-form'> 
                    <div className='form-title'>
                        Change password
                    </div>
                    <input className='form-input form-text' 
                        placeholder="Password" type="password" name="password1"
                        value={pass1.value} onChange={handlePass1}/>
                    <input className='form-input form-text' 
                        placeholder="Verify password" type="password" name="password2"
                        value={pass2.value} onChange={handlePass2}/>
                    <div className='form-footer'>
                        {error!= "" &&<p className='error-message'>{error}</p>}
                        <button className='botton2 header-item header-text' onClick={()=>{sendData()}}>
                            Change
                        </button>
                    
                        <div className='header-item header-text cust-but' onClick={()=>{navigate('/sign-up')}}>
                            Register
                        </div>
                        <div className='header-item header-text cust-but' onClick={()=>{navigate('/login')}}>
                            Login
                        </div>
                    </div>
                </div>
                :
                <div className='custom-form'>
                    <div className='form-title'>
                        {resultMess}
                    </div>
                    <button className='botton2 header-item header-text' onClick={()=>{navigate('/')}}>
                        Home
                    </button>
                        <div className='header-item header-text cust-but' onClick={()=>{navigate('/login')}}>
                            Login
                        </div>
                </div>
                }
           </div>
        </div>
    );
};

export default UpdatePassword;
