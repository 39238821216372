import React,  {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {verfyEmail} from '../../livevoyage/api_client';

const VerifyEmail = () => {
    const { id, token } = useParams()
	console.log(id + ': ' + token);
    const [resultMess, setResultMess] = useState("");
    const [error, setError] = useState("");

    const navigate = useNavigate ();

    const sendData = async() =>{
    
        let res = await verfyEmail(id, token);
        if(res){
            setResultMess(res.message)
        }else {
            setError("Invalid link!");
        }
    }

    useEffect(() => {/*
        let temp =  checkLoggenIn();
        if(temp){
            navigate('/');
        }*/
    })
    return (
        <div className='content-box'>
            <div className='centered'>
                {resultMess == "" ? 
                <div className='custom-form'> 
                    <div className='form-title'>
                        Please, click the button to verify your email.
                    </div>
                    <div className='form-footer'>
                        {error!= "" &&<p className='error-message'>{error}</p>}
                        <button className='botton2 header-item header-text' onClick={()=>{sendData()}}>
                            Verify
                        </button>
                    </div>
                </div>
                :
                <div className='custom-form'>
                    <div className='form-title'>
                        {resultMess}
                    </div>
                    <button className='botton2 header-item header-text' onClick={()=>{navigate('/')}}>
                        Home
                    </button>
                    <div className='header-item header-text cust-but' onClick={()=>{navigate('/login')}}>
                        Login
                    </div>
                </div>
                }
           </div>
        </div>
    );
};

export default VerifyEmail;
