import React, {useEffect, useState, useRef} from 'react';

import { useNavigate, useParams, useLocation } from 'react-router-dom';

import {Dialog} from "@material-ui/core";
import {CloseIconModal, JoinIcon} from "../../components/icons/Icons";

import StreamStates from '../../utils/streamStates';

import VideoPlayer from '../../components/stream/VideoPlayer';
import StreamingView from '../../components/stream/StreamingView';

import background from "../../assets/stream.png"

import ic_location from "../../assets/ic_location.svg"
import ic_time from "../../assets/ic_time.svg"
import {getStream} from "../../livevoyage/api_client";
import ic_avatar from "../../assets/avatar.png"

import config from '../../config/config';

import ContentWrapper from '../../components/content'

function Stream() {

    const { id } = useParams()

    let user = null;
    user = JSON.parse(localStorage.getItem("user"));

    const streamingRef = useRef();
    const playerRef = useRef();

    const navigate = useNavigate();
    const location = useLocation();
    const [stream, setStream] = useState(null);
    const [selectedStream, setSelectedStream] = useState(null);
    const [open, setOpen] = useState(true);
    const [running, setRunning] = useState(true);
    const [currStream, setCurrStream] = useState({});
    const [additionalStreams, setAdditionalStreams] = useState([]);
    const [currPoint, setCurrPoint] = useState(0);


    const onChange = (url)=>{
        //setCurrStream({url:url, prev:null, next:stream.startAt});
    }

        let load = true;
    useEffect(() => {
        loadStream(id);
    },[load])

    const loadStream = async (stream_id) =>{
        const str = await getStream(stream_id);
        await setStream(str);
        await setSelectedStream(str);
        await setCurrStream({url:str.url, start:str.startAt});

        //if(stream == null) navigate(-1);
    }
  
    const finishStreaming = ()=>{
        if(streamingRef.current)
            streamingRef.current.closeStream();
        
        let prev = location.state?.from;
        if(prev) navigate(-1);
        else navigate('/');
    }

    const onChangeTrack = (streamCur) =>{
        setCurrStream({url:streamCur.url, start:streamCur.startAt});
        setSelectedStream(streamCur)
    }
    const onNewAdditions = (additionals) => {
        setAdditionalStreams(additionals);
    }
    const pause =()=>{
        streamingRef.current.closeStream();
    }

if(id)
  return (
    <ContentWrapper>
      {stream && <Dialog fullScreen={true} open={open} onClose={finishStreaming}>
            <div className='stream-detail-modal'>
                <div className='header block_mrg_20'>
                    <div className="stream-user" onClick={()=>{onChangeTrack(stream)}}>
                        {currStream.url == stream.url && <div className='stream-user_live'></div>}
                        <img alt={stream.User.username} className='stream-user_image' src={(stream.User.image != "")?config.http.host+stream.User.image: ic_avatar}/>
                        <p className="stream-user_name ">{stream.User.username}</p>
                    </div>
                    {additionalStreams && additionalStreams.map((additionalStream) => (
                        <div className="stream-user" onClick={()=>{onChangeTrack(additionalStream)}}>
                            {currStream.url == additionalStream.url && <div className='stream-user_live'></div>}
                            <img alt={additionalStream.User.username} className='stream-user_image' src={(additionalStream.User.image != "")?config.http.host+additionalStream.User.image: ic_avatar}/>
                            <p className="stream-user_name ">{additionalStream.User.username}</p>
                        </div>
                    ))}
                    <div onClick={finishStreaming}>
                        <CloseIconModal />
                    </div>
                </div>
                {stream.state === StreamStates.Upcoming && 
                    <div className='detail-card'>
                        <div className='stream-modal-content'>
                            <div className='modal-image'>
                                <img alt={stream.name} className='stream-image' src={(stream.image!="")?config.http.host+stream.image:background}/>
                            </div>
                            <div className='modal-detail'>
                                <div className='stream-detailcontent'>
                                    <div className='stream-details-c'>
                                        <p className='stream-title1'>{stream.name}</p>
                                        <p className='stream-description'>{stream.description}</p>
                                        <div className='stream-details-row'>
                                            { stream.Location && 
                                                <div className='stream-detail-item'>
                                                    <img alt={stream.Location.formattedAddress} className='stream-join' src={ic_location}/>
                                                    <p className="stream-datail-text ">{stream.Location.formattedAddress}</p>
                                                </div>
                                            }
                                            <div className='stream-detail-item'>
                                                <img className='stream-join' src={ic_time}/>
                                                <p className="stream-datail-text ">{new Date(stream.planDate).toLocaleString()}</p>
                                            </div>
                                        </div>
                                        <div className='stream-detailfooter'>
                                            <JoinIcon/>
                                            <p className="stream-join-text">Join</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(stream.state == StreamStates.Live || stream.state == StreamStates.Saved) && 
                    <div className='full'>
                        <VideoPlayer 
                            ref={playerRef}
                            stream = {stream} 
                            endlistener = {pause}
                            playerState = {currStream}
                            additionalStreams={additionalStreams}
                        />
                        <StreamingView 
                            ref={streamingRef} 
                            stream = {stream} 
                            opened = {open} 
                            onClose = {finishStreaming}
                            onNewAdditions = {onNewAdditions}
                            onRunning = {running}
                            onChange={onChange}
                        />
                    </div>
                }
            </div>
        </Dialog>
        }
    </ContentWrapper>
  );
}

export default Stream;
