import React from 'react';
import { useNavigate } from 'react-router-dom';

const Continent = ({continent}) => {
    if(continent)
    return (
        <div className='l_continent'>
            <div className='continent_text'>
                {continent.name}
            </div>
            {continent.live && <div className='continent_live'></div>}
            <div className='continent_layout'></div>
            <img className='continent_image' src={continent.image}></img>
      </div>
    );
};

export default Continent;
